import { IUser } from "@/services/interfaces/users.interface";
import { getTokenFromCookie, validateToken } from "@/utils/auth";
import { create } from "zustand";

type AuthState = {
  isAuthenticated: boolean;
  revalidateAuth: () => Promise<void>;
  loginUser: () => void;
  logoutUser: () => void;
  user: IUser | undefined;
};

export const useAuthStore = create<AuthState>((set) => ({
  isAuthenticated: !!getTokenFromCookie(),
  user: undefined,
  loginUser: () => set({ isAuthenticated: true }),
  logoutUser: () => set({ isAuthenticated: false }),
  revalidateAuth: async () => {
    const user = await validateToken();
    set({ isAuthenticated: !!user });
    set({ user: user });
  },
}));
