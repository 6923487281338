import { FC, Fragment, useState, ReactNode } from "react";
import dynamic from "next/dynamic";
const LayoutBodyWrapper = dynamic(
  () => import("@/layouts/layout-parts/LayoutBodyWrapper")
);
const DashboardHeader = dynamic(
  () => import("@/layouts/layout-parts/DashboardHeader")
);
const DashboardSidebar = dynamic(() => import("@/layouts/DashboardSidebar"));
import { CSSProperties } from "@mui/material/styles/createMixins";

// --------------------------------------------
type DashboardLayoutProps = {
  children?: ReactNode;
};
// --------------------------------------------

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const [sidebarCompact, setSidebarCompact] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const handleCompactToggle = () => setSidebarCompact(!sidebarCompact);
  const handleMobileDrawerToggle = () =>
    setShowMobileSideBar((state) => !state);

  // dashboard body wrapper custom style
  const customStyle: CSSProperties = {
    width: `calc(100% - ${sidebarCompact ? "86px" : "280px"})`,
    marginLeft: sidebarCompact ? "86px" : "280px",
  };

  return (
    <Fragment>
      <DashboardSidebar
        sidebarCompact={sidebarCompact}
        showMobileSideBar={showMobileSideBar}
        setShowMobileSideBar={handleMobileDrawerToggle}
      />

      <LayoutBodyWrapper sx={customStyle}>
        <DashboardHeader
          setShowSideBar={handleCompactToggle}
          setShowMobileSideBar={handleMobileDrawerToggle}
        />

        {children}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
