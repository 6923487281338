// hoc/withAuth.tsx
import React, { ComponentType, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useAuthStore } from "@/stores/useAuthStore";
import AppLoading from "@/layouts/AppLoading";
import { variables } from "@/config/variables";

const withAuth = <P extends JSX.IntrinsicAttributes>(
  WrappedComponent: ComponentType<P>
) => {
  const AuthComponent: React.FC<P> = (props) => {
    const router = useRouter();
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const revalidateAuth = useAuthStore((state) => state.revalidateAuth);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      (async () => {
        await revalidateAuth();
        setLoading(false);
        if (!isAuthenticated) {
          router.push(variables.ssoRedirectUrl);
        }
      })();
    }, [isAuthenticated, router, revalidateAuth]);

    if (loading) {
      return <AppLoading />;
    }

    return isAuthenticated ? <WrappedComponent {...props} /> : null;
  };

  return AuthComponent;
};

export default withAuth;
