import withAuth from "@/hocs/withAuth";
import DashboardLayout from "@/layouts/DashboardLayout";
import { useAuthStore } from "@/stores/useAuthStore";

const Home = () => {
  const { user } = useAuthStore();
  return (
    <DashboardLayout>
      <p>Bem Vindo, {user?.owner?.full_name}</p>
    </DashboardLayout>
  );
};

export default withAuth(Home);
