import { AuthService } from "@/services/auth.service";
import { IUser } from "@/services/interfaces/users.interface";
import { parseCookies } from "nookies";

export const getTokenFromCookie = () => {
  const { "traad.token": token } = parseCookies();
  return token;
};

export const validateToken = async (): Promise<IUser | undefined> => {
  const token = getTokenFromCookie();
  if (!token) return undefined;

  const authService = new AuthService();
  try {
    const response = await authService.loadUserSSR(token);
    const user: IUser = response.data;
    return user;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};
